import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Input from "./Input";
import { createAccount, loginAccount } from "../lib/ApiEcyd";
import { useNavigate, useParams } from "react-router-dom";
import landing from "../resources/landing.json";
import {
  messageAlert,
  messageSuccess,
  messageError,
  messageAlertTimer,
  SWAL_DISMISS_TIMER,
} from "../lib/AlertsUtils";
import { NavHashLink as Link } from "react-router-hash-link";
import EventBus from "../lib/EventBus";
import {
  categoryErrorMessage,
  emailErrorMessage,
  textErrorMessage,
  phoneErrorMessage,
  passwordErrorMessage,
  validateEmail as emailPattern,
  validateTextRange as textRangePattern,
  validatePhone as numberPattern,
  validatePassword as passwordPattern,
} from "../utils/validations";
import ApiErrorMapper from "../lib/ApiErrorMapper";
import { getApiServicesNames } from "../constants/ApiServices";
import { useCup } from "../context/cup-context";

export default function HeaderSign({
  cupUuid,
  seasonUuid,
  categories,
  enrollmentDates,
}) {
  const navigate = useNavigate();
  const initInputTouchedValues = {
    email: false,
    password: false,
    confirmPassword: false,
    teamName: false,
    name: false,
    lastName: false,
    secondLastName: false,
    phone: false,
    playersCount: false,
    categoryUuid: false,
  };
  const [account, setAccount] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    teamName: "",
    name: "",
    lastName: "",
    secondLastName: "",
    phone: "",
    playersCount: "",
    categoryUuid: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isTouched, setInputTouched] = useState(initInputTouchedValues);
  const activeCategories = categories?.filter((cat) => cat?.fullCapacity === false) || [];
  const _categories = [{ uuid: "", name: activeCategories.length > 0 ? "Categorías" : "Sin categorías" }, ...activeCategories];
  const { cupPermalink } = useParams();
  const token = localStorage.getItem("token") || null;

  const [playerCountOptions, setPlayerCountOptions] = useState([
    { uuid: 0, name: "Número de jugadores" },
  ]);
  const services = getApiServicesNames();

  const onChange = (e) => {
    const { name, value } = e.target;
    setAccount({
      ...account,
      [name]: value,
    });
    setInputTouched({
      ...isTouched,
      [name]: true,
    });
  };

  const onChangeCategory = (e) => {
    const categoryUuid = e.target.value;
    const category = categories.find(({ uuid }) => uuid === categoryUuid);
    const selectCategory = document.getElementById("categoryUuid");
    const selectPlayers = document.getElementById("playersCount");
    if (category) {
      const options = [];
      for (let i = category.minPlayers; i <= category.maxPlayers; i++)
        options.push(i);
      setPlayerCountOptions(options);
      // to style select since they dont have placeholder attribute
      selectPlayers.classList.remove("first:italic");
      selectPlayers.classList.remove("first:text-gray-400");
      selectCategory.classList.remove("first:italic");
      selectCategory.classList.remove("first:text-gray-400");
      selectCategory.classList.remove("focus:ring-red-500");
      selectCategory.classList.remove("focus:border-red");
      selectCategory.classList.add("focus:border-gray-500");
      selectCategory.classList.add("focus:ring-gray-500");
    } else {
      setPlayerCountOptions([{ uuid: 0, name: "Número de jugardores" }]);
      // to style select since they dont have placeholder attribute
      selectPlayers.classList.add("first:italic");
      selectPlayers.classList.add("first:text-gray-400");
      selectCategory.classList.add("first:italic");
      selectCategory.classList.add("first:text-gray-400");
      selectCategory.classList.remove("focus:border-gray-500");
      selectCategory.classList.remove("focus:ring-gray-500");
      selectCategory.classList.add("focus:ring-red-500");
      selectCategory.classList.add("focus:border-red");
    }
    setAccount({
      ...account,
      [e.target.name]: categoryUuid,
      playersCount: category?.minPlayers || 0,
    });
    setInputTouched({
      ...isTouched,
      categoryUuid: true,
    });
  };

  const onFocusPlayerCount = (e) => {
    if (playerCountOptions[0].uuid === 0)
      setPlayerCountOptions([
        { uuid: 0, name: "Primero seleccione una categoria" },
      ]);
  };

  const onCloseModal = () => {
    if (!loading) {
      setShowModal("");
      setTermsAndConditions(false);
      setInputTouched({
        ...isTouched,
        email: false,
        password: false,
        confirmPassword: false,
      });
    }
  };

  const handleRegisterButton = () => {
    if (token) {
      navigate(`/${cupPermalink}/account`);
    } else {
      setShowModal("register");
      setAccount({
        ...account,
        email: "",
      });
    }
  };

  const handleRegisterForm = async (e) => {
    for (const key in account) {
      if (!account[key]) {
        messageAlert(
          "Oops!", // title
          `Parece que no has completado todos los campos`, // text
          "info", // type
          true, // showConfirmButton
          "Entendido" // confirmButtonText
        );
        return;
      }
    }

    setLoading(true);
    const payload = {
      ...account,
      playersCount: Number(account.playersCount),
      cupUuid,
      seasonUuid,
    };
    const response = await createAccount(payload);
    setLoading(false);

    if (!response || !("success" in response)) {
      const error = ApiErrorMapper(services.register, response.data.error.Code);
      messageError("Error", error);

      return;
    }

    messageSuccess(
      "Registro exitoso!",
      `Hemos enviado un correo de confirmación a su cuenta ${account.email}. Es necesario confirmar su cuenta para poder iniciar sesión.`
    );
    setTermsAndConditions(false);
    setAccount({ email: account?.email, password: "" });
    setInputTouched(initInputTouchedValues);
    setShowModal("login");
  };

  const handleLoginForm = async (e) => {
    if (!account.email || !account.password) {
      const fieldName = !account.email
        ? `Correo electrónico`
        : !account.password
        ? "Contraseña"
        : "";
      messageAlert(
        "Oops!", // title
        `Parece que no has completado el campo ${fieldName}.`, // text
        "info", // type
        true, // showConfirmButton
        "Entendido" // confirmButtonText
      );
      return;
    }
    if (!RegExp(emailPattern).test(account.email)) {
      messageAlert(
        "Oops!", // title
        'El campo "Correo electrónico" no tiene el formato correcto', // text
        "info", // type
        true, // showConfirmButton
        "Entendido" // confirmButtonText
      );
      return;
    }

    setLoading(true);
    setTimeout(async () => {
      const payload = {
        userName: account.email,
        password: account.password,
        cupUuid: cupUuid,
      };
      const response = await loginAccount(payload);
      setLoading(false);

      if (!response?.success) {
        const error = ApiErrorMapper(services.login, response.data.error.Code);
        messageError("Error", error);
        return;
      }
      messageSuccess("Inicio de sesión exitoso!");

      setAccount({});
      setShowModal("");
      sessionStorage.setItem(
        "cupPermalink",
        `${cupPermalink}#${cup.data?.name}`
      );
      navigate(`/${cupPermalink}/account`);
    }, 1000);
  };

  const handleLoginButton = () => {
    if (token) {
      logout();
      return;
    }
    setShowModal("login");
  };

  const logout = () => {
    messageAlertTimer("Cerrando sesión").then((result) => {
      if (result.dismiss === SWAL_DISMISS_TIMER) {
        localStorage.removeItem("token");
        localStorage.removeItem("account");
        sessionStorage.removeItem("cupPermalink");
        navigate(`/${cupPermalink}`);
      }
    });
  };

  const inputStyle =
    "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-10/12 mx-auto sm:text-sm border-gray-300 rounded-md mt-4";

  EventBus.$on("openModal", (modal) => setShowModal(modal));

  const { getCupColor, cup, getCupActiveSession } = useCup();

  const cupSession = getCupActiveSession();
  const registerBanner = () => {
    return enrollmentDates ? (
      <div
        className={`flex w-full justify-end items-center text-white text-sm font-bold p-1`}
        role="alert"
      >
        <button
          style={{ color: getCupColor() }}
          className={`whitespace-nowrap px-8 py-2 font-medium`}
          onClick={handleLoginButton}
        >
          {token
            ? loading
              ? landing.accessSign.modalButtons.loginOut
              : landing.accessSign.modalButtons.logout
            : landing.accessSign.modalButtons.login}{" "}
          {token && loading && <span className="loader inverse"></span>}
        </button>
        <button
          style={{ backgroundColor: getCupColor() }}
          className={`ml-4 whitespace-nowrap inline-flex items-center justify-center px-8 py-2 border border-transparent rounded shadow-sm text-base font-medium text-white`}
          onClick={() => handleRegisterButton()}
        >
          {token
            ? landing.accessSign.modalButtons.myAccount
            : landing.accessSign.modalButtons.createAccount}
        </button>
      </div>
    ) : (
      <div
        style={{ backgroundColor: getCupColor() }}
        className={`flex w-full place-content-center items-center text-white text-sm font-bold px-4 py-3`}
        role="alert"
      >
        <svg
          className="fill-current w-4 h-4 mr-2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" />
        </svg>
        <p>Registro disponible muy pronto!</p>
      </div>
    );
  };
  const changeCupSessionBanner = () => {
    return (
      <div
        style={{ backgroundColor: getCupColor() }}
        className={`flex justify-between items-center text-white text-sm font-bold p-2 w-full`}
        role="alert"
      >
        <div className="flex">
          <svg
            className="fill-current w-4 h-4 mr-2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" />
          </svg>
          <p>
            Tienes una sesión activa en {cupSession.name}, cierra sesión para
            ingresar en {cup.data?.name}
          </p>
        </div>
        <button
          style={{
            color: getCupColor(),
            backgroundColor: "white",
            borderRadius: ".25rem",
          }}
          className={`whitespace-nowrap px-8 py-2 font-medium`}
          onClick={handleLoginButton}
        >
          <strong>
            {loading
              ? landing.accessSign.modalButtons.loginOut
              : landing.accessSign.modalButtons.logout}{" "}
          </strong>
          {token && loading && <span className="loader inverse"></span>}
        </button>
      </div>
    );
  };

  return (
    <div className={`flex w-full`}>
      {cupSession !== null && cupSession.permalink !== cup.data?.permalink
        ? changeCupSessionBanner()
        : registerBanner()}
      {showModal === "register" && (
        <Transition.Root show={showModal === "register"} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={onCloseModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed z-10 inset-0 overflow-y-auto">
              <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm sm:w-full sm:p-6">
                    <div className="mt-2 text-center sm:mt-1">
                      <Dialog.Title
                        style={{ color: getCupColor() }}
                        as="h3"
                        className={`text-lg leading-6 font-bold`}
                      >
                        {landing.accessSign.form.register.title}
                      </Dialog.Title>

                      <Input
                        divClassName={inputStyle}
                        name="teamName"
                        placeholder={
                          landing.accessSign.form.placeholders.teamName
                        }
                        disabled={loading}
                        errorMessage={textErrorMessage(
                          account?.teamName,
                          isTouched.teamName,
                          "Nombre de equipo"
                        )}
                        pattern={textRangePattern(3, "")}
                        onChange={onChange}
                        value={account.teamName}
                        onKeyUp={(e) =>
                          e.key === "Enter" && handleRegisterForm()
                        }
                      />

                      {/* Start selects */}

                      <Input
                        divClassName={`w-10/12 mx-auto mt-4`}
                        options={_categories}
                        attributeName="name"
                        name="categoryUuid"
                        type="select"
                        disabled={loading}
                        value={account.categoryUuid}
                        errorMessage={categoryErrorMessage(
                          account?.categoryUuid,
                          isTouched.categoryUuid
                        )}
                        onChange={onChangeCategory}
                        onKeyUp={(e) =>
                          e.key === "Enter" && handleRegisterForm()
                        }
                        className={"first:text-gray-400 first:italic"}
                      />

                      <Input
                        divClassName={`w-10/12 mx-auto mt-4`}
                        options={playerCountOptions}
                        name="playersCount"
                        type="select"
                        defaultValue="0"
                        disabled={loading}
                        value={account.playersCount}
                        onChange={onChange}
                        onFocus={onFocusPlayerCount}
                        onKeyUp={(e) =>
                          e.key === "Enter" && handleRegisterForm()
                        }
                        className={"first:text-gray-400 first:italic"}
                      />

                      <Input
                        divClassName={inputStyle}
                        name="name"
                        placeholder={landing.accessSign.form.placeholders.name}
                        disabled={loading}
                        errorMessage={textErrorMessage(
                          account.name,
                          isTouched.name,
                          "Nombre(s) del responsable",
                          2
                        )}
                        pattern={textRangePattern(2, "")}
                        onChange={onChange}
                        value={account.name}
                        onKeyUp={(e) =>
                          e.key === "Enter" && handleRegisterForm()
                        }
                      />

                      <Input
                        divClassName={inputStyle}
                        name="lastName"
                        placeholder={
                          landing.accessSign.form.placeholders.lastName
                        }
                        disabled={loading}
                        errorMessage={textErrorMessage(
                          account.lastName,
                          isTouched.lastName,
                          "Apellido Paterno",
                          2
                        )}
                        pattern={textRangePattern(2, "")}
                        onChange={onChange}
                        value={account.lastName}
                        onKeyUp={(e) =>
                          e.key === "Enter" && handleRegisterForm()
                        }
                      />

                      <Input
                        divClassName={inputStyle}
                        name="secondLastName"
                        placeholder={
                          landing.accessSign.form.placeholders.secondLastName
                        }
                        disabled={loading}
                        errorMessage={textErrorMessage(
                          account.secondLastName,
                          isTouched.secondLastName,
                          "Apellido materno",
                          2
                        )}
                        pattern={textRangePattern(2, "")}
                        value={account.secondLastName}
                        onChange={onChange}
                        onKeyUp={(e) =>
                          e.key === "Enter" && handleRegisterForm()
                        }
                      />

                      <Input
                        divClassName={inputStyle}
                        name="phone"
                        placeholder={landing.accessSign.form.placeholders.phone}
                        type="tel"
                        disabled={loading}
                        errorMessage={phoneErrorMessage(
                          account.phone,
                          isTouched.phone
                        )}
                        pattern={numberPattern}
                        onChange={onChange}
                        value={account.phone}
                        onKeyUp={(e) =>
                          e.key === "Enter" && handleRegisterForm()
                        }
                      />

                      <Input
                        divClassName={inputStyle}
                        name="email"
                        placeholder={landing.accessSign.form.placeholders.email}
                        type="email"
                        disabled={loading}
                        errorMessage={emailErrorMessage(
                          account?.email,
                          isTouched.email
                        )}
                        value={account.email}
                        onChange={onChange}
                        onKeyUp={(e) =>
                          e.key === "Enter" && handleRegisterForm()
                        }
                        pattern={emailPattern}
                      />

                      <Input
                        divClassName={inputStyle}
                        name="password"
                        placeholder={
                          landing.accessSign.form.placeholders.password
                        }
                        type="password"
                        disabled={loading}
                        errorMessage={passwordErrorMessage(
                          account.password,
                          isTouched.password
                        )}
                        onChange={onChange}
                        pattern={passwordPattern.pattern()}
                        onKeyUp={(e) =>
                          e.key === "Enter" && handleRegisterForm()
                        }
                      />

                      <Input
                        divClassName={inputStyle}
                        name="confirmPassword"
                        placeholder={
                          landing.accessSign.form.placeholders.confirmPassword
                        }
                        type="password"
                        disabled={loading}
                        errorMessage={
                          account.password !== account?.confirmPassword &&
                          isTouched.confirmPassword
                            ? 'El campo "Confirmar contraseña" no coincide con el campo "Contraseña'
                            : ""
                        }
                        pattern={passwordPattern.pattern()}
                        onChange={onChange}
                        onKeyUp={(e) =>
                          e.key === "Enter" && handleRegisterForm()
                        }
                      />

                      <fieldset className="space-y-5 mt-8">
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5 ">
                            <input
                              type="checkbox"
                              className="focus:ring-pink-700 h-4 w-4 text-pink-700 border-gray-300 rounded ml-7"
                              onChange={() =>
                                setTermsAndConditions(!termsAndConditions)
                              }
                            />
                          </div>
                          <div className="ml-3 text-sm">
                            <label
                              htmlFor="comments"
                              className="font-bold text-gray-700"
                            >
                              {
                                landing.accessSign.form.register
                                  .termsAndConditions
                              }
                            </label>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                    <div className="mt-6 mb-3 sm:mt-6 pl-14 mt-4">
                      <button
                        style={{ backgroundColor: getCupColor() }}
                        disabled={!termsAndConditions}
                        type="button"
                        className={`btn btn-pink inline-flex justify-center w-4/5 ${
                          (!termsAndConditions ||
                            account?.password !== account?.confirmPassword ||
                            (!account?.password &&
                              !account?.confirmPassword)) &&
                          "opacity-50 cursor-not-allowed"
                        }`}
                        onClick={handleRegisterForm}
                      >
                        {loading
                          ? landing.accessSign.form.register.submitting
                          : landing.accessSign.form.register.submit}
                        {loading && <span className="loader"></span>}
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      )}

      {showModal === "login" && (
        <Transition.Root show={showModal === "login"} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={onCloseModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed z-10 inset-0 overflow-y-auto">
              <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm sm:w-full sm:p-6">
                    <div className="mt-2 text-center sm:mt-1">
                      <Dialog.Title
                        as="h3"
                        style={{ color: getCupColor() }}
                        className="text-lg leading-6 font-bold"
                      >
                        {landing.accessSign.form.login.title}
                      </Dialog.Title>

                      <Input
                        divClassName={inputStyle}
                        name="email"
                        placeholder={landing.accessSign.form.placeholders.email}
                        type="email"
                        value={account.email}
                        disabled={loading}
                        errorMessage={emailErrorMessage(
                          account?.email,
                          isTouched.email
                        )}
                        onChange={onChange}
                        onKeyUp={(e) => e.key === "Enter" && handleLoginForm()}
                        pattern={emailPattern}
                      />
                      <Input
                        divClassName={inputStyle}
                        name="password"
                        placeholder={
                          landing.accessSign.form.placeholders.password
                        }
                        type="password"
                        errorMessage={
                          !account?.password?.length && isTouched.password
                            ? 'El campo "Contraseña" es requerido'
                            : ""
                        }
                        disabled={loading}
                        onChange={onChange}
                        onKeyUp={(e) => e.key === "Enter" && handleLoginForm()}
                      />

                      <div className="text-sm mt-5 text-right pr-8">
                        <Link
                          to={`/${cupPermalink}/password/reset`}
                          className="text-sm text-sky-400 truncate underline"
                        >
                          ¿Olvidaste tu contraseña?
                        </Link>
                      </div>
                    </div>
                    <div className="mt-4 mb-3 sm:mt-6 pl-10 sm:pl-14">
                      <button
                        style={{ backgroundColor: getCupColor(), outlineColor: getCupColor() }}
                        type="button"
                        className={`inline-flex justify-center w-4/5 btn-ecyd-base`} 
                        onClick={handleLoginForm}
                      >
                        {loading
                          ? landing.accessSign.form.login.submitting
                          : landing.accessSign.form.login.submit}
                        {loading && <span className="loader"></span>}
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      )}
    </div>
  );
}

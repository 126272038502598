/* This example requires Tailwind CSS v2.0+ */
import { UserIcon } from '@heroicons/react/solid'
import CheckBlackIcon from '../assets/check-black-icon.png'
import CheckGreenIcon from '../assets/check-green-icon.png'
import EditIcon from '../assets/edit-icon.png'
import DeleteIcon from '../assets/delete-icon.png'
import { NavHashLink as Link } from 'react-router-hash-link';

export default function StackedList({rows, active, cupPermalink='', entity='player', onDelete, season = {}}) {
    const activeClass = "bg-white shadow overflow-hidden sm:rounded-md md:w-8/12 sm:w-full";
    const inactiveClass = "bg-white shadow overflow-hidden sm:rounded-md md:w-8/12 disable-link sm:w-full";


const onClickDelete = (uuid, name) => {
    onDelete(entity, uuid, name)
}

const time = Date.now();
const days =  new Date(season?.extensionEnrollmentAt) - new Date();

return (
    <>
        <div className={active ? activeClass : inactiveClass}>
            <ul className={`divide-y`}>
                {rows.map((row) => (
                <li key={row.uuid} className="my-1 last:my-0">
                    <span className={`${!row.isComplete ? 'border-red-500 border-2 rounded-md' : row.reinforcements ?  'border-blue-500 border-2 rounded-md' : 'border-green-500 border-2 rounded-md'} block hover:bg-gray-50`}>
                        <div className="px-4 py-4 sm:px-6">
                            <div className="mt-2 sm:flex sm:justify-between">
                                <div className="sm:flex">
                                    <p className="flex items-center text-sm text-gray-500 ">
                                        {
                                            row.photoUrl ?
                                                <img
                                                    className="inline-block h-10 w-10 rounded-full"
                                                    src={`${row.photoUrl}?${time}`}
                                                    alt={row.uuid}
                                                />
                                            :
                                            <UserIcon className="flex-shrink-0 mr-1.5 h-10 w-10 text-gray-400" aria-hidden="true" />
                                        }
                                        <span className='ml-5'>{`${row.name} ${row.lastName} ${(row.secondLastName) ? row.secondLastName : ''} ${(row.reinforcements) ? ' [Refuerzo]' : ''}`}</span>
                                    </p>
                                </div>
                                <div className=" lg:ml-0 flex lg:text-center items-center sm:ml-0 sm:text-center text-sm text-gray-500">
                                    {
                                        !row.isVerified && days > 0 &&
                                        <>
                                            <Link to={`/${cupPermalink}/${entity}/${row.permalink}/#`}> 
                                                <img
                                                    className="flex-shrink-0 mr-1.5 h-5 w-5"
                                                    src={EditIcon}
                                                    alt="edit-icon"
                                                />
                                            </Link>
                                            <button
                                                onClick={(e)  => onClickDelete(row.uuid, (row.name + ' ' + row.lastName + ' ' + row.secondLastName))}
                                            >
                                                <img
                                                    className="flex-shrink-0 h-5 w-5 ml-3 mr-4"
                                                    src={DeleteIcon}
                                                    alt="delete-icon"
                                                />
                                            </button>
                                        </>
                                    }
                                    {row.isVerified ? 
                                        <img
                                            className="flex-shrink-0 mr-1.5 h-5 w-5"
                                            src={CheckGreenIcon}
                                            alt="check-green"
                                        /> :
                                        <img
                                            className="flex-shrink-0 mr-1.5 h-5 w-5"
                                            src={CheckBlackIcon}
                                            alt="check-black"
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </span>
                </li>
                ))}
            </ul>
        </div>
    </>
)
}

import { useEffect, useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useParams, useNavigate } from "react-router-dom";
import {
  CheckIcon,
  InformationCircleIcon,
  TagIcon,
} from "@heroicons/react/solid";
import HeadingEcyd from "../components/HeadingEcyd";
import Navbar from "../components/NavbarEcyd";
import AccountForm from "../containers/AccountForm";
import Footer from "../containers/FooterEcyd";
import ManageTeamSection from "../containers/ManageTeamSection";
import SponsorSection from "../containers/SponsorSection";
import { Attributes } from "../constants/CupData";
import {
  getAccount,
  updateAccount,
  DeleteEntity,
  addAccountToSeason,
} from "../lib/ApiEcyd";
import { getDayBetweenDates } from "../lib/DateUtils";
import { enrollmentDatesValidation } from "../lib/helpers";
import HeaderSign from "../components/AccessSign";
import {
  messageError,
  messageDelete,
  messageAlertTimer,
  messageAlertCallback,
  SWAL_DISMISS_TIMER,
} from "../lib/AlertsUtils";
import { useCup } from "../context/cup-context";
import ErrorPage from "./ErrorPage";
import Input from "../components/Input";
import ApiErrorMapper from "../lib/ApiErrorMapper";
import { getApiServicesNames } from "../constants/ApiServices";
import BoletiaPaymentForm from "../components/BoletiaPaymentForm";

const AccountPage = ({ phone = "" }) => {
  const { cupPermalink } = useParams();
  const { cup, getCupByPermalink, getCupColor } = useCup();
  const [showModal, setShowModal] = useState(false);
  const [showBoletiaModal, setShowBoletiaModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [categoryUuid, setCategoryUuid] = useState("");
  const [playersCount, setPlayerCount] = useState(0);
  const [playerCountOptions, setPlayerCountOptions] = useState([
    { uuid: 0, name: "Número de jugadores" },
  ]);
  const services = getApiServicesNames();
  useEffect(() => {
    getCupByPermalink(cupPermalink);
  }, [cupPermalink, getCupByPermalink]);

  const [account, setAccount] = useState({
    loading: false,
    data: {},
    hasError: null,
  });

  // Initial effect with charge the component
  useEffect(() => {
    getAccount(account, setAccount);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (account.hasError) {
      if (account.data.error.Code === 414) {
        setShowModal(true);
        return;
      }
    }
    
    setTimeout(() => {
      Object.keys(account.data).length > 0 && setShowBoletiaModal(!account.data.isPaid)
    }, 1200)
  }, [account]);
  const addAccountToNewSeason = async () => {
    const response = await addAccountToSeason({ categoryUuid, playersCount });
    if (!response?.success) {
      setLoading(false);
      setShowModal(false);
      messageError(
        `${validatePaymentCodeError(response.error.Code)}`
      );
      return;
    }
    window.location.reload();
  };
  const LinkAccountModal = ({ categories, isOpen }) => {
    const navigate = useNavigate();
    const onChangeCategory = (e) => {
      setLoading(true);
      const categoryUuid = e.target.value;
      const category = categories.find(({ uuid }) => uuid === categoryUuid);
      if (!category)
        return setPlayerCountOptions([
          { uuid: 0, name: "Número de jugardores" },
        ]);
      const options = [];
      for (let i = category.minPlayers; i <= category.maxPlayers; i++)
        options.push(i);
      setCategoryUuid(categoryUuid);
      setPlayerCountOptions(options);
      if (options.length) setPlayerCount(options[0]);
      setLoading(false);
    };
    const logout = () => {
      messageAlertTimer("Cerrando sesión").then((result) => {
        if (result.dismiss === SWAL_DISMISS_TIMER) {
          localStorage.removeItem("token");
          localStorage.removeItem("account");
          navigate(`/${cupPermalink}`);
        }
      });
    };
    return (
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={logout}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-xl sm:w-full sm:p-6">
                  <div className="mt-2 text-center sm:mt-1">
                    <Dialog.Title
                      as="div"
                      className="leading-6 px-8 text-gray-700"
                    >
                      <p className="text-3xl font-bold">
                        Parece que no estás inscrito en la temporada actual.
                      </p>{" "}
                      <br />
                      <p className="text-lg">
                        * Para inscribirte en la temporada actual es necesario
                        completar los siguientes campos.
                      </p>
                    </Dialog.Title>

                    <Input
                      divClassName={`w-10/12 mx-auto mt-8`}
                      options={[
                        { uuid: "", name: "Categorías" },
                        ...categories,
                      ]}
                      attributeName="name"
                      name="categoryUuid"
                      type="select"
                      disabled={loading}
                      value={categoryUuid}
                      onChange={onChangeCategory}
                      className={"first:text-gray-400 first:italic"}
                    />

                    <Input
                      divClassName={`w-10/12 mx-auto mt-4`}
                      options={playerCountOptions}
                      name="playersCount"
                      type="select"
                      value={playersCount}
                      disabled={loading}
                      className={"first:text-gray-400 first:italic"}
                      onChange={(e) => setPlayerCount(Number(e.target.value))}
                    />
                  </div>
                  <div className="flex mt-4 mb-3 sm:mt-6 sm:px-14 ">
                    <button
                      type="button"
                      className="justify-center w-4/5 mr-4 bg-gray-500 text-white rounded-lg shadow-md"
                      onClick={logout}
                    >
                      Cancelar
                    </button>

                    <button
                      style={
                        categoryUuid === "" && playersCount === 0
                          ? {
                              pointerEvents: "none",
                              opacity: 0.4,
                              backgroundColor: getCupColor(),
                            }
                          : { backgroundColor: getCupColor() }
                      }
                      type="button"
                      className="inline-flex justify-center w-4/5 btn-pink"
                      onClick={addAccountToNewSeason}
                    >
                      Inscribirme
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };
  if (cup.hasError) return <ErrorPage error={cup.hasError} type="cup" />;
  if (account.hasError) {
    return (
      <Fragment>
        {!cup.loading && Object.keys(cup.data).length && showModal && (
          <LinkAccountModal
            categories={cup?.data?.season?.categories || []}
            isOpen={showModal}
            onClose={(event) => setShowModal(event)}
          />
        )}
        <ErrorPage error={account.hasError} type="account" />
      </Fragment>
    );
  }

  const attributes = Attributes(cup.data?.permalink || "");
  let message;
  const validatePaymentCodeError = (code) => {
    switch (code) {
      case 510:
        message = "Lo sentimos, cupo lleno, te esperamos la próxima edición";
        break;
      case 511:
        message =
          "Ya no se pueden inscribir equipos, después del cierre de pago";
        break;
      case 512:
        message = "Ya cerramos inscripciones, te esperamos la próxima edición";
        break;
      default:
        message = `Por el momento no puedes realizar el pago. Comunícate al número ${cup.data.contact.phoneNumber}`;
    }
    return message;
  };
 
  //onSubmit to update account in DB
  const onSubmit = async (e, account) => {
    e.preventDefault();
    account.playersCount = parseInt(account.playersCount);
    const response = await updateAccount(account, cup.data.contact.phoneNumber);
    if (!response.success) {
      const error = ApiErrorMapper(services.register, response.data.error.Code);
      messageAlertCallback(
        `Error`,
        `<strong>${error}</strong>`,
        "info",
        false,
        "Enterado",
      ).then(async (result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    }
    getAccount(account, setAccount);
  };

  const onDeletePlayer = async (entity, uuid, name) => {
    const entityName = entity === "player" ? "jugador" : "entrenador";
    const html = <h5> {name} </h5>;
    messageDelete(
      `¿Desear borrar al siguiente ${entityName}?`,
      html,
      "warning",
      async () => {
        await DeleteEntity(entity, uuid);
        getAccount(account, setAccount);
      }
    );
  };

  const limitPaymentDate = cup.data?.season?.limitPaymentDate
    ? new Date(cup.data.season.limitPaymentDate)
    : new Date();
  let days =
    !cup.loading && cup.data.season
      ? getDayBetweenDates(new Date(), limitPaymentDate)
      : 0;

  return (
    !cup.loading &&
    !account.loading &&
    cup.data &&
    cup.data.season && (
      <div className="max-w-5xl mx-auto px-4 sm:px-6">
        <HeaderSign
          cupUuid={cup.data?.uuid}
          seasonUuid={cup.data?.season?.uuid || ""}
          categories={cup.data?.season?.categories}
          enrollmentDates={enrollmentDatesValidation(cup?.data?.season)}
        />
        <Navbar
          logoUrl={cup.data.imageUrl || attributes.cupImgUrl}
          menuSections={attributes.menu}
        />
        <HeadingEcyd title="Mi cuenta" cupPermalink={cup.data.permalink} />

        <div className="shadow overflow-hidden sm:rounded-md mt-8">
          {account.data.isPaid ? (
            <div className="w-full inline-flex text-title text-green-500 bg-green-100 border-green-300 p-3 rounded-md">
              <p
                className="inline-flex w-full justify-center"
                id="is-paid-description"
              >
                <CheckIcon className="h-6 w-6" aria-hidden="true" />
                Inscripción pagada
              </p>
            </div>
          ) : days >= 0 ? (
            <>
              <div
                className={`${
                  days < 5
                    ? "bg-red-100 text-red-400"
                    : "bg-blue-100 text-blue-400"
                } w-full py-2 sm:inline-flex text-title rounded-sm inline-block text-center`}
              >
                <p className="text-center inline-flex">
                  <InformationCircleIcon
                    className="h-6 w-12"
                    aria-hidden="true"
                  />
                  Recuerda que ahora el pago para el registro de equipos por categoría se realizará a través del sitio web
                </p>
                <div className="inline-flex mx-2">
                  <a
                    className="bg-blue-500 hover:bg-blue-700 text-white font-normal text-sm py-1 px-2 rounded"
                    target="_blank"
                    href="https://boletia.com/"
                    rel="noreferrer">Ir a Boletia.com
                  </a>
                </div>
              </div>

              <div className="shadow overflow-hidden sm:rounded-md mt-2">
                <div
                  className="flex bg-green-100 text-green-400 w-full py-2 sm:justify-between text-title rounded-sm inline-block text-center"
                >
                  <p className="flex text-center inline-flex">
                    <TagIcon
                      className="h-6 w-12"
                      aria-hidden="true"
                    />
                    Si ya cuentas con un código de Boletia puedes canjearlo/aplicarlo
                  </p>
                  <div className="mx-2">
                    <button
                      onClick={() => setShowBoletiaModal(true)}
                      className="bg-green-500 hover:bg-green-700 text-white font-normal text-sm py-1 px-2 rounded"
                    >
                      Canjear código
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div
              className={`bg-blue-100 text-blue-400 w-full mb-6 py-2 inline-flex justify-center text-title rounded-sm`}
            >
              <p className="text-center">
                Te esperamos para la próxima edición
              </p>
            </div>
          )}
        </div>

        <AccountForm
          isPaid={account.data.isPaid}
          accountUser={account.data}
          onSubmit={onSubmit}
          categories={cup.data.season.categories || []}
        />
        {account.data.isPaid && (
          <ManageTeamSection
            cup={cup.data}
            account={account.data}
            onDeletePlayer={onDeletePlayer}
          />
        )}
        <section id="sponsors" className="mt-10 bg-secondary">
          <SponsorSection
            sponsors={cup.data.sponsors}
            titleStyle={
              "text-title-primary text-center leading-8 tracking-tight sm:text-4xl sm:tracking-tight"
            }
          />
        </section>
        <Footer
          logoUrl={cup.data.imageUrl || attributes.cupImgUrl}
          menuSections={attributes.menu}
          termsAndConditionsUrl={cup.data?.files?.termsAndConditionsDocUrl}
        />
        {/* Modal */}
        <BoletiaPaymentForm
          categories={cup?.data?.season?.categories || []}
          isOpen={showBoletiaModal}
          onClose={() => setShowBoletiaModal(false)}
        />
      </div>
    )
  );
};

export default AccountPage;

/* eslint-disable jsx-a11y/anchor-is-valid */
import { Attributes } from '../constants/CupData';
import { NavHashLink as Link } from 'react-router-hash-link';

const HeadingEcyd = ({ title = "", cupPermalink, sections = [], breadcrumb = false}) => {
    const { bannerImgUrl } = Attributes(cupPermalink);

    const getBreadcrumbClass = (index) => {
        const last = sections.length - 1;
        let breadcrumbStyle = 'text-2xl ml-1 text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white';
        if (index === last) breadcrumbStyle = 'text-2xl ml-1 text-gray-500 md:ml-2 dark:text-gray-400';
        return breadcrumbStyle;
    }

    return (  
        <>
            <div className="relative py-5">
                {
                    !breadcrumb 
                    ?
                        <h2 className="text-title-secondary text-left leading-8 tracking-tight sm:text-1xl sm:tracking-tight">
                            {title}
                        </h2>
                    :
                        <nav className="flex text-title-secondary text-gray-700 rounded-lg" aria-label="Breadcrumb">
                            <ol className="inline-flex items-center space-x-1 md:space-x-3">
                                {
                                    sections.map((section, i) => {
                                        return i === 0
                                        ?
                                            <li key={i} className="inline-flex items-center">
                                                <Link
                                                    isActive={false}
                                                    className="text-xl inline-flex items-center text-2xl text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
                                                    to={`/${cupPermalink}/account`}
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" id="IconChangeColor" height="24" width="24"><rect width="256" height="256" fill="none"></rect><path d="M231.8,134.8a4.8,4.8,0,0,0,0-1.2c.1-1.9.2-3.7.2-5.6a103.2,103.2,0,0,0-23-65.1,5.5,5.5,0,0,0-1.4-1.7,103.9,103.9,0,0,0-41.1-29.8l-1.1-.4a103.4,103.4,0,0,0-74.8,0l-1.1.4A103.9,103.9,0,0,0,48.4,61.2,5.5,5.5,0,0,0,47,62.9,103.2,103.2,0,0,0,24,128c0,1.9.1,3.7.2,5.6a4.8,4.8,0,0,0,0,1.2,104.2,104.2,0,0,0,15.7,48.4,9.9,9.9,0,0,0,1.1,1.7,104.3,104.3,0,0,0,60.3,43.6h.3a104.2,104.2,0,0,0,52.8,0h.3A104.3,104.3,0,0,0,215,184.9a9.9,9.9,0,0,0,1.1-1.7A104.2,104.2,0,0,0,231.8,134.8ZM68.5,117.1l13.2,4.3,12.7,39.2-8.1,11.2H51.7a86.2,86.2,0,0,1-11.2-34.3Zm119,0,28,20.4a86.2,86.2,0,0,1-11.2,34.3H169.7l-8.1-11.2,12.7-39.2ZM193.2,69l-10.7,32.9-13.2,4.3L136,81.9V68.1l28-20.4A87,87,0,0,1,193.2,69ZM92,47.7l28,20.4V81.9L86.7,106.2l-13.2-4.3L62.8,69A87,87,0,0,1,92,47.7Zm18,166.4L99.3,181.2l8.1-11.2h41.2l8.1,11.2L146,214.1a86.2,86.2,0,0,1-36,0Z" id="mainIconPathAttribute"></path></svg>
                                                    {section}
                                                </Link>
                                            </li>
                                        :
                                            <li key={i}>
                                                <div className="flex items-center">
                                                    <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                                                    <p className={getBreadcrumbClass(i)}>
                                                        {section}
                                                    </p>
                                                </div>
                                            </li>
                                    })
                                }
                            </ol>
                        </nav>
                }
            </div>
            <div className="bg-secondary overflow-hidden rounded-lg">
                <img src={bannerImgUrl} alt={cupPermalink}/>
            </div>
        </>
    );
}
 
export default HeadingEcyd;
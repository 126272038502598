import MapSection from "../containers/MapSection";
import ContactSection from "../containers/ContactSection";
import SponsorSection from "../containers/SponsorSection";
import Navbar from "../components/NavbarEcyd";
import HeaderSign from "../components/AccessSign";
import { Attributes } from "../constants/CupData";
import ContentWithImage from "../containers/ContentWithImage";
import InfoCards from "../components/InfoCards";
import { useParams, useLocation } from "react-router-dom";
import { useEffect } from "react";
import ErrorPage from "./ErrorPage";
import { useCup } from "../context/cup-context";
import Footer from "../containers/FooterEcyd";
import landing from "../resources/landing.json";
import EventBus from "../lib/EventBus";
import { enrollmentDatesValidation } from "../lib/helpers";
import { messageAlert } from "../lib/AlertsUtils";
import BoletiaInfo from "../components/BoletiaInfo";


const CupHomePage = () => {
  const { cupPermalink } = useParams();
  const search = useLocation().search;
  const openLogin = new URLSearchParams(search).get("openLogin");

  const { cup, getCupByPermalink, getCupColor, setCup, setCupPermalink } = useCup();

  useEffect(() => {
    getCupByPermalink(cupPermalink);
    if (Boolean(openLogin))
      setTimeout(() => EventBus.$dispatch("openModal", "login"), 300);
  }, [cupPermalink, getCupByPermalink, openLogin]);

  useEffect(() => {
    if(Object.keys(cup.data).length > 0 && cup.data.gallery?.modalData) {
      setTimeout(() => {
        messageAlert(
          "", // title
          <BoletiaInfo />, // text
          "info", // type
          true, // showConfirmButton
          "Entendido",
          0,
        );
      }, 500)
    }
  },[cup, cupPermalink]);

  // to unmount component
  useEffect(() => {
    return () => {
      sessionStorage.removeItem("cupPermalink");
      setCup({
        loading: true,
        data: {},
        hasError: null,
      });
      setCupPermalink('');
    };
  }, [setCup, setCupPermalink]);

  const attributes = Attributes(cupPermalink);

  if (cup.hasError) return <ErrorPage error={cup.hasError} type="cup" />;

  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });

  // if (
  //   cupPermalink === "copa-timon" &&
  //   enrollmentDatesValidation(cup?.data?.season)
  // ) {
  // if (
  //   cupPermalink === "copa-timon"
  // ) {
  //   messageAlert(
  //     "¡Cupo Lleno!", // title
  //     `¡Los esperamos en nuestra próxima edición!`, // text
  //     "warning", // type
  //     true, // showConfirmButton
  //     "Entendido" // confirmButtonText
  //   );
  // }

  return (
    Object.keys(cup.data).length &&
    !cup?.loading ? (
      cup?.data.permalink === "copa-rc-sur" ?
      <a 
      href={"https://docs.google.com/forms/d/e/1FAIpQLSeWDbp4Di4MmR7wro5vYyoXm5BcnBuEXjVewjz973m65ezoNA/viewform"}    
      style={{ backgroundColor: getCupColor() }} 
      className={`w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white md:py-4 md:text-lg md:px-10`}>
        <strong>¡Click para ir al sitio!</strong>
      </a>
      :
      <div className="max-w-5xl mx-auto px-4 sm:px-6">
        <HeaderSign
          cupUuid={cup.data?.uuid}
          seasonUuid={cup.data?.season?.uuid || ""}
          categories={cup.data?.season?.categories}
          enrollmentDates={enrollmentDatesValidation(cup?.data?.season)}
        />
        <Navbar
          logoUrl={cup.data.imageUrl || attributes.cupImgUrl}
          menuSections={attributes.menu}
        />
        <ContentWithImage
          enrollmentDates={enrollmentDatesValidation(cup?.data?.season)}
          cupPermalink={cupPermalink}
          title={cup.data.name}
          description={cup.data.description}
          image={
            process.env.REACT_APP_BASE_S3_IMG_URL +
              cup.data.permalink +
              "-cover.png" || cup.data.imageUrl
          }
        />
        <InfoCards
          dates={cup.data?.gallery?.dates}
          categories={cup.data?.gallery?.categories}
          inscriptionDates={cup.data?.gallery?.inscriptionDates}
          modalData={cup.data?.gallery?.modalData}
        />
        <section id="map" className="bg-blue-secondary">
          <MapSection
            latitude={cup.data.latitude}
            longitude={cup.data.longitude}
            place={cup.data?.place || ""}
            address={cup.data.address}
          />
        </section>
        <section id="contact">
          <ContactSection
            contactPhone={cup.data.contact.phoneNumber}
            showBall={false}
            showSocialNetworks={true}
            fbUrl={cup.data.socialNetworks.facebookUrl}
            igUrl={cup.data.socialNetworks.instagramUrl}
            appStoreAppUrl={cup.data.socialNetworks.appStoreAppUrl}
            playStoreAppUrl={cup.data.socialNetworks.playStoreAppUrl}
          />
        </section>
        <section id="sponsors" className="bg-blue-secondary">
          <SponsorSection
            sponsors={cup.data.sponsors}
            titleStyle={
              "text-title-primary-black text-center leading-8 tracking-tight sm:text-4xl sm:tracking-tight"
            }
          />
        </section>
        <Navbar
          logoUrl={cup.data.imageUrl || attributes.cupImgUrl}
          menuSections={attributes.menu}
        />
        <Footer
          logoUrl={landing.logoUrl}
          menuSections={landing.menu}
          showNavbar={false}
          termsAndConditionsUrl={cup.data?.files?.termsAndConditionsDocUrl}
        />
      </div>
    ) : (
      <div className="w-11/12 p-4 h-screen m-auto">
        <div className="animate-pulse flex space-x-4 flex-col lg:w-4/12 lg:h-2/6 m-auto mt-[200px] w-5/12 h-2/6">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-full h-full">
            <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 18.75h-9m9 0a3 3 0 0 1 3 3h-15a3 3 0 0 1 3-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 0 1-.982-3.172M9.497 14.25a7.454 7.454 0 0 0 .981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 0 0 7.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 0 0 2.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 0 1 2.916.52 6.003 6.003 0 0 1-5.395 4.972m0 0a6.726 6.726 0 0 1-2.749 1.35m0 0a6.772 6.772 0 0 1-3.044 0" />
          </svg>
          <span className="text-center lg:text-xl text-lg font-bold">Cargando información de la copa...</span>
        </div>
      </div>
    )
  );
};

export default CupHomePage;

const BoletiaInfo = () => {

    return (
      <div>
        <p className="text-xl mb-6">Recuerda que ahora el registro por categoría se realizará a través del sitio web Boletia</p>
        <a target="_blank" href="https://boletia.com/" style={{color: "blue"}} rel="noreferrer">Ir al Boletia.com</a>
      </div>
    )
}

export default BoletiaInfo

import { useState, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Input from './Input';
import { useCup } from '../context/cup-context';

import {
	validateEmail as emailPattern,
	validateBoletia as boletiaPattern,
	emailErrorMessage,
	boletiaCodeErrorMessage,
} from '../utils/validations';
import { redeemBoletiaPaymentCode } from '../lib/ApiEcyd';
import ApiErrorMapper from '../lib/ApiErrorMapper';
import { getApiServicesNames } from '../constants/ApiServices';
import { messageError } from '../lib/AlertsUtils';

const BoletiaPaymentForm = ({ isOpen = true, categories = [], onClose }) => {
	const { getCupColor } = useCup();
	const services = getApiServicesNames();
	const initInputClickedValues = {
		email: false,
		boletiaCode: false,
		categoryUuid: false,
	};
	const [isClicked, setInputClicked] = useState(initInputClickedValues);
	const loading = false;
	const [categoryUuid, setCategoryUuid] = useState('');
	const [code, setCode] = useState('');
	const [email, setEmail] = useState('');
	const isSubmitDisable = () =>
		Boolean(
			categoryUuid === '' ||
				email === '' ||
				emailErrorMessage(email, isClicked.email, 'Correo electrónico').length >
					0 ||
				code === '' ||
				boletiaCodeErrorMessage(code, isClicked.boletiaCode, 'Código Boletia')
					.length > 0
		);
	const submitButtonStyle = isSubmitDisable()
		? {
				pointerEvents: 'none',
				opacity: 0.4,
				backgroundColor: getCupColor(),
		  }
		: { backgroundColor: getCupColor() };

	const onChangeCategory = (e) => {
		const categoryUuid = e.target.value;
		setCategoryUuid(categoryUuid);
	};

	const submitBoletiaCode = async () => {
		const data = { code, email, categoryUuid };
		const response = await redeemBoletiaPaymentCode(data);
		if (response.status !== 200) {
			const error = ApiErrorMapper(services.payment, response.data.error.Code);
			messageError('Error al canjear código Boletia', error);
			return;
		}
		window.location.reload();
	};

	const onChange = (event) => {
		const { name, value } = event.target;
		if (name === 'email') setEmail(value.trim());
		else setCode(value.trim());
		setInputClicked({
			...isClicked,
			[name]: true,
		});
	};
	return (
		<Transition.Root
			show={isOpen && !Boolean(sessionStorage.getItem('boletia_modal'))}
			as={Fragment}
		>
			<Dialog as="div" className="relative z-10" onClose={onClose}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed z-10 inset-0 overflow-y-auto">
					<div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-xl sm:w-full sm:p-6">
								<div className="mt-2 text-center sm:mt-1">
									<Dialog.Title
										as="div"
										className="leading-6 px-8 text-gray-700"
									>
										<p className="text-3xl font-bold">
											Ingresa el código proporcionado por Boletia
										</p>{' '}
										<br />
										<p className="text-lg">
											* Recuerda que el código sólo puede ser canjeado una vez
										</p>
										<p className="text-lg">
											* ¡Importante!, El registro de tu equipo se activará 24
											horas después de realizar el pago en Boletia
										</p>
									</Dialog.Title>

									<Input
										divClassName={`w-10/12 mx-auto mt-8`}
										options={[{ uuid: '', name: 'Categorías' }, ...categories]}
										attributeName="name"
										name="categoryUuid"
										type="select"
										disabled={loading}
										value={categoryUuid}
										onChange={onChangeCategory}
										className={'first:text-gray-400 first:italic'}
									/>

									<Input
										divClassName={`w-10/12 mx-auto mt-4`}
										name="email"
										placeholder="Correo electrónico"
										type="email"
										value={email}
										errorMessage={emailErrorMessage(
											email,
											isClicked.email,
											'Correo electrónico'
										)}
										onChange={onChange}
										pattern={emailPattern}
									/>

									<Input
										divClassName={`w-10/12 mx-auto mt-4`}
										name="boletiaCode"
										type="text"
										placeholder="Código Boletia"
										value={code}
										disabled={loading}
										className={'first:text-gray-400 first:italic'}
										onChange={onChange}
										errorMessage={boletiaCodeErrorMessage(
											code,
											isClicked.boletiaCode,
											'Código Boletia'
										)}
										pattern={boletiaPattern}
									/>
								</div>
								<div className="flex mt-4 mb-3 sm:mt-6 sm:px-14 ">
									<button
										type="button"
										className="justify-center w-4/5 mr-4 bg-gray-500 text-white rounded-lg shadow-md"
										onClick={onClose}
									>
										Cancelar
									</button>

									<button
										style={submitButtonStyle}
										type="button"
										className="inline-flex justify-center w-4/5 rounded-lg shadow-md btn-pink"
										onClick={submitBoletiaCode}
									>
										Canjear código
									</button>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

export default BoletiaPaymentForm;

export const ErrorMessages = {
    imageFormat: {
        title: "Error Formato de Imagen", 
        description: "Solo se aceptan formato de Imágenes: 'jpeg', 'jpg', 'png', 'svg'",
    },
    noAcceptPdf: {
        title: "No se acepta formato PDF", 
        description: "Solo se aceptan formato de Imágenes: 'jpeg', 'jpg', 'png', 'svg'",
    }
}
